<template>
    <div class="" >
      <BaseLoading
        style="min-height: 400px; height: 90vh"
        v-if="loading"
      ></BaseLoading>
  
      <div class="p-sm-4  p-1" v-else>
  
        <div class=" is-order-card mt-2 ">
          <div class="  " style="border-radius: 20px">
            <div class="row" v-if="Object.keys(classHelpOrder).length > 0">
              <div class="col-12 col-sm-4 col-md-4 text-left" sm="6">
                <!-- {{ classHelpOrder }} -->
                <h1
                class="order-id"
                >
                  Class ID # {{ classHelpOrder.id || "" }}
                </h1>
                <p >
                  
                  <strong>{{ classHelpOrder.no_weeks || "" }}Week(s)</strong> |
                  <strong>{{ classHelpOrder.no_pages || "" }}Page(s) </strong> |
                  <span
                  class="
                  
                    mx-sm-2 mx-1 my-sm-0 my-2
                  "
                  >Status: <span v-html="classHelpOrder.status" ></span>  </span
                >
                </p>
                
              </div>
  
              <div class="col-12 col-sm-8 col-md-8" sm="6">
                <div class="m-0 text-sm-right text-left">
                <span v-if="classHelpOrder.buttons && classHelpOrder.buttons.length > 0">
                <span v-for="(action, index) in classHelpOrder.buttons" :key="index + 'oa'">
                    <router-link
                    v-if="action.is_link"
                    :to="{ name: action.action, params: { id: classHelpOrder.id } }"
                    class="btn m-1"
                    :class="`btn-${action.color || 'primary'}`"
                    >{{ action.title }}</router-link
                    >
                    <button
                    v-else
                    class="btn m-1"
                    @click="order_action = action"
                    :class="`btn-${action.color || 'primary'}`"
                    data-toggle="modal"
                    data-target="#exampleModal1"
                    >
                    {{ action.title }}
                    </button>
                </span>
                </span>
                  
                  <div class="btn-group">
                    
                   
                    <button
                      class="btn btn-outline-primary"
                      data-toggle="modal"
                      data-target="#walletModal"
                    >
                      Wallet Topup
                    </button>
                  </div>
                </div>
              </div>
            </div>
  
            <ul class="nav nav-tabs d-flex align-items-center justify-content-center w-100 text-center nav-tabs-bottom pl-0">
              <li class="nav-item">
                <a
                  href="#info"
                  class="nav-link px-sm-4 active show font-weight-bold text-uppercase"
                  data-toggle="tab"
                  >Class Info</a
                >
              </li>
             
            </ul>
  
            <div class="tab-content  orderlisting--card card p-4 my-3">
              <div class="tab-pane fade active show" id="info">
                <div  class="order-info text-left">
                    <div class="row">
                    <div class="col-12 col-sm-9 table-responsive">
                        <div class="order-details-item my-2 order-details-item-paper-detailes">
                        <div class="order-details-header d-flex justify-content-between">
                            <div class="title py-2 ">Class Help Details</div>
                        </div>                                     
                        
                        </div>
                        <table
                        class="table table-borderless table--custom text-left "
                        style="font-size: 13px !important"
                        >
                        <tr>
                            <td>Date Ordered</td>
                            <td>{{ classHelpOrder.create_stamp || "" }}</td>
                            <!-- <td></td> -->
                        </tr>





            
                        <tr>
                            <td>Number of Page(s)</td>
                            <td>
                            {{ classHelpOrder.no_pages || "" }} pages
                            </td>
                            <!-- <td></td> -->
                        </tr>
                        <tr>
                            <td>Number of Week(s)</td>
                            <td>{{ classHelpOrder.no_weeks || "" }} weeks
                            
                        </td>
                            <!-- <td></td> -->
                        </tr>


                        <tr class="alert alert-danger border-0">
                            <td class="font-weight-bold">Balance</td>
                            <td class="font-weight-bold" style="font-size: 18px;">${{ classHelpOrder.balance || 0 }}</td>
                            <!-- <td></td> -->
                        </tr>
                        </table>
            
                        <div class="order-details-item my-4 order-details-item-paper-detailes">
                        <div class="order-details-header d-flex justify-content-between">
                            <div class="title py-2 ">Class Help Instructions</div>
                        </div>                                     
                        <div class="order-details-block">
                            <div class="order-option-value order-option-instructions" data-full-text="In ducimus mollitia In ducimus mollitia In ducimus mollitia <span class='see-less-instructions'>See less</span>" data-truncate-text="In ducimus mollitia In ducimus mollitia In ducimus mollitia"> 
                                <pre style="padding: 4px !important">                 
                                <span class="class_desc" v-html="classHelpOrder.description"></span>
                                </pre>
                            </div>
                        </div>
                        </div>
                        
                    </div>

                    <!-- <div class="col-12 my-2 col-sm-3">
                      james
                    </div> -->
            
            
                    
            
                    
                    </div>
            
                   
                </div>
  
  
  
                
              </div>
  
              
            </div>
          </div>
        </div>
  
        <div  class=" ">
          <div
            class="card d-flex flex-row flex-wrap  p-4 my-4 text-left"
            v-if="classHelpOrder.buttons && classHelpOrder.buttons.length > 0"
          >
                <span v-for="(action, index) in classHelpOrder.buttons" :key="index + 'oa'">
                    <router-link
                    v-if="action.is_link"
                    :to="{ name: action.action, params: { id: classHelpOrder.id } }"
                    class="btn m-1"
                    :class="`btn-${action.color || 'primary'}`"
                    >{{ action.title }}</router-link
                    >
                    <button
                    v-else
                    class="btn m-1"
                    @click="order_action = action"
                    :class="`btn-${action.color || 'primary'}`"
                    data-toggle="modal"
                    data-target="#exampleModal1"
                    >
                    {{ action.title }}
                    </button>
                </span>
                  
                  <div class="btn-group">
                    
                   
                    <button
                      class="btn btn-outline-primary"
                      data-toggle="modal"
                      data-target="#walletModal"
                    >
                      Wallet Topup
                    </button>
                  </div>
          </div>
        </div>
  
        
  
        <UpdateOrderStatus :action="order_action" :writers="writers" ></UpdateOrderStatus>
      </div>
  
      <BaseModal :title="'Wallet Topup'" :modalId="'walletModal'">
        <WalletTopup></WalletTopup>
      </BaseModal>
  
      <BaseModal :title="'Wallet Topup'" :modalId="'tipModal'">
        <TipWriter></TipWriter>
      </BaseModal>
    </div>
  </template>
  
  <script>
  import WalletTopup from "../../components/payments/modals/WalletTopup.vue";
  import TipWriter from "../../components/payments/modals/TipWriter.vue";
  import OrderInfo from "../../components/order/OrderInfo.vue";
  import OrdersubInfo from "../../components/order/OrdersubInfo.vue";
  import WriterOrderInfo from "../../components/order/WriterOrderInfo.vue";
  import EditorOrderInfo from "../../components/order/EditorOrderInfo.vue";
  import OrderFiles from "../../components/order/OrderFiles.vue";
  import OrderMessages from "../../components/order/OrderMessages.vue";
  import UpdateOrderStatus from "../../components/order/modals/UpdateOrderStatus.vue";
  import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
  import FineComponent from "../../components/payments/FineComponent.vue";
  import DueCounter from "../../components/order/DueCounter.vue";
  import http from "../../modules/http.index";
  
  // import Rating from "../../components/order/Rating.vue";
  export default {
    name: "ViewClass",
    components: {
      OrderInfo,
      WriterOrderInfo,
      EditorOrderInfo,
      OrderFiles,
      OrderMessages,
      // Rating,
      UpdateOrderStatus,
      WalletTopup,
      TipWriter,
      FineComponent,
      DueCounter,
      OrdersubInfo
    },
    computed: {
    //   ...mapState("order", ["order"]),
      ...mapGetters("auth", ["userType", "userTypes"]),
      ...mapState("auth", ["user"]),
      ...mapState("order", ["order", 'classHelpOrder']),

  
    },
    data: () => ({
      order_action: {},
      deadline: false,
      writers:[],
      loading:false
    }),
    async mounted() {
      this.loading = true;
    //   await this._getOrder(this.$route.params.id).catch((err) => {
    //     console.log("err.response", err.response);
    //     if (err && err.response && err.response.status == 404) {
    //       this.$router.push({ name: "Home404" });
    //     }
    //   });
      await this._getClassHelpOrder(this.$route.params.id)
        .then(() => (this.loading = false))
        .catch(() => {
            this.$router.push({ name: "404" });
        });
      this.getWriters();
      await this._getMessages(this.order.id);
      await this.getSetups();
      this.timeout(this.order.urgency_text);
      this.loading = false;
    },
    methods: {
    //   ...mapActions("order", ["_getOrder"]),
      ...mapActions("setups", ["getSetups"]),
      ...mapActions("messages", ["_getMessages"]),
      ...mapMutations("order", ["unsetOrder", "changeStatus"]),
      ...mapActions("order", ["_getOrder", "_getClassHelpOrder"]),

      getWriters() {
        http
          .get(`/Orders/AvailableWriters/${this.order.id}`)
          .then((res) => {
            console.log("response for writers====>", res);
            this.writers =
              res.data.Data && res.data.Data.writers ? res.data.Data.writers : [];
          })
          .catch((err) => {
            console.log("getting writers error", err);
            // this.$notify({
            //   title: "Error",
            //   text:
            //     err.response && err.response.data
            //       ? err.response.data.Message
            //       : "Error getting writers",
            //   style: "danger",
            // });
          });
      },
  
      timeout(payload) {
        // console.log(payload);
        var urgency = payload / 1000;
        var d1 = 24 * 60 * 60;
        var d2 = 60 * 60;
  
        setInterval(() => {
          urgency--;
  
          var days = Math.floor(urgency / d1),
            hours = Math.floor(urgency / d2) % 24,
            minutes = Math.floor(urgency / 60) % 60,
            seconds = Math.floor(urgency / 1) % 60;
  
          this.deadline = `${days} Days, ${hours} Hours, ${minutes} Minutes, ${seconds} Seconds`;
        }, 1000);
      },
    },
  
    beforeDestroy() {
      this.unsetOrder();
    },
  };
  </script>
  
  <style scoped lang="scss">
  .nav-tabs {
    margin-bottom: 0px !important;
  }
  .order-title {
    max-height: 32px;
    overflow: hidden;
  }
  
  @media screen and (min-width: 768px) {
    .is-order-card {
      min-height: 74vh;
    }
  }
  
  .is-animated {
    transition: 0.3s ease-in-out;
  }
  .nav-tabs a.nav-link {
    padding: 5px 9px;
  }
  </style>